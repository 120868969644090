<script>
import TextField from '../inputs/text.vue'
import { formatCurrency } from '@/utils'
export default {
  extends: TextField,
  props: {
    label: {
      type: String,
      default: 'Amount'
    },
    name: {
      type: String,
      default: 'amount'
    },
    rules: {
      type: String,
      default: 'required|min:1'
    },
    formatAsCurrency: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'number'
    },
  },
  computed: {
    readonlyValue() {
      if (this.formatAsCurrency) {
        return formatCurrency(this.innerValue)
      }
      return this.innerValue
    }
  }
}
</script>
